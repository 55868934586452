import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import '../css/pages/contatti.css'
import '../css/aos.css'

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

const ContattiPage = ({ pathContext: { locale } }) => (
	<>
		<Helmet
			bodyAttributes={{
				className: 'transition-support webkit contatti'
      }}
      title="Contatti | Studio Legale Cirio - Francescon - Stella - Sartori - Maccari"
		>
		</Helmet>
		<Layout locale={locale}>
			<div className="contatti">
				<div className="content clearfix">
          <div className="section-block intro-title-2 header-image redFilter"><p className="text-left">TRIESTE</p><p className="text-right">UDINE</p></div>
					<section className="section-block replicable-content contact-2">
            <div className="row">
              <div className="column width-6 map-embed" data-aos = "fade-right" data-aos-duration = "500">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d388.15318653293707!2d13.775953965585945!3d45.65274268484008!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9e5c0b94f68b1fae!2sStudio%20Legale%20Cirio%20-%20Francescon%20-%20Stella%20-%20Sartori%20-%20Maccari%20Studio%20Legale%20Associato%20Cirio%20-%20Francescon%20-%20Stella!5e0!3m2!1sit!2sit!4v1568991627664!5m2!1sit!2sit" width="600" height="450" frameborder="0" style={{border: 'none'}} allowfullscreen=""></iframe>
              </div>
              <div className="column width-6 map-embed" data-aos = "fade-left" data-aos-duration = "500">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d781.2926085661526!2d13.235963786001667!3d46.06757422330683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477a4aa712231f69%3A0xcf256d70ca07a189!2sStudio%20Legale%20Cirio%20Francescon%20Stella!5e0!3m2!1sit!2sit!4v1566999197438!5m2!1sit!2sit" width="600" height="450" frameborder="0" style={{border: 'none'}} allowfullscreen=""></iframe>
              </div>
            </div>
						<div className="row" data-aos = "fade-right" data-aos-duration = "500">
							<div className="column width-4">
								<h2 data-aos-duration = "500" className="mb-30"><FormattedMessage id="contattiTitle" /></h2>
								<div className="row">
									<div className="column width-10">
										<p><FormattedHTMLMessage id="contattiRecapito1" /></p>
										<p><FormattedHTMLMessage id="contattiRecapito2" /></p>
									</div>
								</div>
							</div>
							<div className="column width-8 left">
								<div className="contact-form-container">
									<form name="Contatti" method="POST" data-netlify="true" netlify-honeypot="bot-field" action="/grazie">
                    <input type="hidden" name="form-name" value="Contatti" />
										<div className="row">
											<div className="column width-6" data-aos = "fade-right">
												<FormattedMessage id="contattiName">
												{(message) => <input type="text" name="nome" className="form-fname form-element large" placeholder={message} tabindex="1" required /> }
												</FormattedMessage>
											</div>
											<div className="column width-6" data-aos = "fade-left">
												<FormattedMessage id="contattiLastname">
												{(message) => <input type="text" name="cognome" className="form-lname form-element large" placeholder={message} tabindex="2" required /> }
												</FormattedMessage>
											</div>
											<div className="column width-6" data-aos = "fade-left" data-aos-duration = "500">
												<FormattedMessage id="contattiEmail">
												{(message) => <input type="email" name="email" className="form-email form-element large" placeholder={message} tabindex="3" required />}
												</FormattedMessage>
											</div>
											<div className="column width-6" data-aos = "fade-right" data-aos-duration = "500">
												<FormattedMessage id="contattiPhone">
												{(message) => <input type="text" name="telefono" className="form-phone form-element large" placeholder={message} tabindex="4" required />}
												</FormattedMessage>
											</div>
											<div className="column width-6">
												<input type="text" name="honeypot" className="form-honeypot form-element large" />
											</div>
										</div>
										<div className="row">
											<div className="column width-12">
												<div className="field-wrapper" data-aos = "fade-left" data-aos-duration = "800">
													<textarea name="messaggio" className="form-message form-element large" placeholder="messaggio" tabindex="7" required />
												</div>
											</div>
											<div className="column width-12">
												<div className="field-wrapper pt-10 pb-10" data-aos = "fade-up" data-aos-duration = "500">
													<input id="checkbox-1" className="form-element checkbox" name="checkbox-1" type="checkbox" required />
													<label for="checkbox-1" className="checkbox-label"><a className="checkbox-label" href="/files/informativa.pdf" target="_blank" style={{ marginBottom: '3px' }}><FormattedHTMLMessage id="contattiPersonalData" /></a></label>
												</div>
											</div>
											<div className="column width-12" data-aos = "fade-up" data-aos-duration = "500">
												<input type="submit" value="invia  >" className="input-button form-submit button medium color-white color-hover-white" />
											</div>
										</div>
									</form>
									<div className="form-response center"></div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</Layout >
	</>
)

export default ContattiPage
